import cornMazeImage from '../assets/cornmaze/field.jpg';
import cornMazeLogo from '../assets/cornmaze/logo.png';
import Included, { WhatsIncluded } from '../components/cornmaze/Included';
import TicketInfo from '../components/cornmaze/TicketInfo';
import { useClasses } from '../hooks/classes';
import { CornMazeEventProps } from '../models/layout-props';
import R from '../resources';
import styles from './CornMaze.module.css';

export default function CornMaze() {
    return (
        <div className={useClasses('container-fluid', styles.Container)[0]}>
            <div className={styles.Title}>
                <div className={styles.Cover}>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <img src={cornMazeLogo} alt='Corn Maze' className='w-100' />
                        </div>
                        <div className='col-12 col-md-6'>
                            <img
                                src={cornMazeImage}
                                alt="The Broyan's Farm Corn Maze"
                                className='w-100'
                            />
                        </div>
                    </div>
                </div>
                <EventInformation />
            </div>

            <WhatsIncluded />

            <CornMazeEvent
                name='Craft Show'
                date='October 1st, 2022'
                image='https://cdn.pixabay.com/photo/2018/08/13/07/15/master-3602432_960_720.jpg'
            />
            <CornMazeEvent
                name='Corn Hole Tournament'
                date='October 8th, 2022'
                image='https://cdn.shopify.com/s/files/1/2622/1638/files/img_beanbagtoss.jpg?8828221938971581871'
            />
            <CornMazeEvent
                name='Car Show'
                date='October 15th, 2022'
                image='https://images.unsplash.com/photo-1584315365708-0e3162887c6c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=872&q=80'
            />

            {/* <TicketInfo /> */}
        </div>
    );
}

function EventInformation() {
    return (
        <div className='container-fluid bg-light p-4'>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <strong style={{ textAlign: 'start' }}>{R.strings.cornmaze.date_range}</strong>

                    <table>
                        <tr>
                            <td>
                                <strong>{R.strings.cornmaze.weekend}</strong>
                            </td>
                            <td>{R.strings.cornmaze.weekend_hours}</td>
                        </tr>

                        <tr>
                            <td>
                                <strong>{R.strings.cornmaze.sunday}</strong>
                            </td>
                            <td>{R.strings.cornmaze.sunday_hours}</td>
                        </tr>
                    </table>
                </div>
                <div className='col-12 col-md-6 text-end'>
                    <strong>{R.strings.short_name}</strong>
                    <br />
                    {R.strings.cornmaze.address_line1}
                    <br />
                    {R.strings.cornmaze.address_line2}
                </div>
            </div>
        </div>
    );
}

function CornMazeEvent({ name, date, image }: CornMazeEventProps) {
    return (
        <div className={`${styles.CornMazeEvent} p-4`} style={{ backgroundImage: `url(${image})` }}>
            <div className='row'>
                <div className='col-12 col-md-8 col-lg-6'>
                    <div className='bg-light p-4 float-left'>
                        <h3 className='display-1'>{name}</h3>
                        <p className='display-4 text-success'>{date}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
