import Address from '../components/locations/Address';
import GoogleMapsEmbed from '../components/locations/GoogleMapsEmbed';
import Hours from '../components/locations/Hours';
import PhoneNumber from '../components/locations/PhoneNumber';
import RibbonH3 from '../components/ui/RibbonH3';
import { useMetaDescription } from '../hooks/meta';

function Locations() {
    useMetaDescription('locations');

    return (
        <div className='body container mt-4 mb-4'>
            <div className='row'>
                <div className='col'>
                    <h1 className='text-center mt-4 mb-4'>Locations / Hours</h1>
                    <RibbonH3>Broyan's Farm Market &amp; Country Store</RibbonH3>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <Address />
                    <Hours />
                    <PhoneNumber />
                </div>

                <div className='col'>
                    <GoogleMapsEmbed />
                </div>
            </div>
        </div>
    );
}

export default Locations;
