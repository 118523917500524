import { useState } from 'react';
import { HeroProps, HeroTitleProps } from '../../models/component-props';
import './Hero.css';

export default function Hero(props: HeroProps) {
    const [winHeight, setWinHeight] = useState(window.innerHeight);
    const [winWidth, setWinWidth] = useState(window.innerWidth);

    let resizeEventLock = false;

    window.addEventListener('resize', () => {
        if (!resizeEventLock) {
            resizeEventLock = true;

            setWinHeight(window.innerHeight);
            setWinWidth(window.innerWidth);

            setTimeout(() => {
                resizeEventLock = false;
            }, 5000);
        }
    });

    return (
        <div
            className='hero container-fluid d-flex flex-column justify-content-center align-items-center'
            style={{
                backgroundImage: `url(${props.background})`,
                backgroundSize: winHeight > winWidth ? 'auto 100%' : '100%',
            }}>
            <div>{props.children}</div>
        </div>
    );
}

export function HeroTitle({ title }: HeroTitleProps) {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <h1 className='hero-title mb-4'>{title}</h1>
        </div>
    );
}
