import { useEffect } from 'react';
import { useMetaDescription } from '../hooks/meta';
import { getAllMerchandise } from '../resources/Merchandise';
import { getEntireCart } from '../util/CartManager';

export default function CheckoutPage() {
    useMetaDescription('checkout');

    useEffect(() => {
        initPayPalButton();

        return () => {
            const paypalButtonContainer = document.querySelector('#paypal-button-container');
            if (paypalButtonContainer) {
                paypalButtonContainer.innerHTML = '';
            }
        };
    }, []);

    return (
        <div className='body container'>
            <div className='row mt-4'>
                <div className='col' />
                <div className='col text-center'>
                    <h1>Checkout</h1>
                    <p className='mb-4'>Select a payment method</p>

                    <div
                        style={{ textAlign: 'center', marginTop: '0.625rem' }}
                        id='paypal-button-container'></div>
                </div>
                <div className='col' />
            </div>
        </div>
    );
}

function initPayPalButton() {
    const purchase_units: PurchaseUnit[] = getEntireCart().map(item => {
        const definition = getAllMerchandise().find(merch => merch.pid === item.id);

        if (definition) {
            return {
                amount: {
                    value: definition.price.toString(),
                },
                description: `${definition.product} - ${item.size} / ${item.color}`,
                reference_id: (Math.random() * 10000).toString(),
            };
        } else {
            return {
                amount: {
                    value: '0',
                },
                description: `${item.size} / ${item.color}`,
                reference_id: (Math.random() * 10000).toString(),
            };
        }
    });

    // function validate(event: HTMLElement) {
    //     return (event as HTMLInputElement).value.length > 0;
    // }

    paypal
        .Buttons({
            style: {
                color: 'gold',
                shape: 'rect',
                label: 'paypal',
                layout: 'vertical',
            },

            createOrder: function (_: unknown, actions: PayPalActions) {
                return actions.order.create({
                    purchase_units,
                });
            },

            onApprove: function (_: unknown, actions: PayPalActions) {
                return actions.order.capture().then(orderData => {
                    // Full available details
                    console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                    // Show a success message within this page, e.g.
                    const element = document.getElementById('paypal-button-container');
                    if (element) {
                        element.innerHTML = '<h3>Thank you for your payment!</h3>';
                    }

                    // Or go to another URL:  actions.redirect('thank_you.html');
                });
            },

            onError: function (err: Error) {
                console.log(err);
            },
        })
        .render('#paypal-button-container');
}
