import { useEffect, useState } from 'react';
import Cart from './activity/Cart';
import CheckoutPage from './activity/CheckoutPage';
import CornMaze from './activity/CornMaze';
import FieldTrips from './activity/FieldTrips';
import Homepage from './activity/Homepage';
import Locations from './activity/Locations';
import OurProducePage from './activity/OurProducePage';
import StoreRouter from './activity/StoreRouter';
import './App.css';
import Footer from './components/ui/Footer';
import Navbar from './components/ui/Navbar';
import { getPath } from './util/URLHash';

const pages: Record<string, JSX.Element> = {
    home: <Homepage />,
    produce: <OurProducePage />,
    locations: <Locations />,
    store: <StoreRouter />,
    cart: <Cart />,
    checkout: <CheckoutPage />,
    cornmaze: <CornMaze />,
    fieldtrips: <FieldTrips />,
};

function App() {
    const [page, setPage] = useState(<Homepage />);

    useEffect(() => {
        function handlePageChange() {
            const hash = getPath()[0];

            setPage(pages[hash] ?? <Homepage />);
        }

        handlePageChange();

        window.addEventListener('hashchange', handlePageChange);

        return () => {
            window.removeEventListener('hashchange', handlePageChange);
        };
    }, []);

    return (
        <div className='App'>
            <Navbar />
            {page}
            <Footer />
        </div>
    );
}

export default App;
