import { ProduceCardProps } from '../../models/component-props';
import R from '../../resources';
import RibbonH3 from '../ui/RibbonH3';

function OurFoods() {
    return (
        <div className='container p-4'>
            <div className='row'>
                <div className='col'>
                    <RibbonH3>Our Foods</RibbonH3>
                </div>
            </div>

            <div className='row'>
                {R.data.produce.foods.map((food: ProduceCardProps, index: number) => (
                    <div className='col-12 col-md-4' key={index}>
                        <ProduceCard {...food} />
                    </div>
                ))}
            </div>
        </div>
    );
}

function ProduceCard(props: ProduceCardProps) {
    return (
        <div className='card w-100'>
            <img className='card-img-top' src={props.image} alt={props.produce} />
            <div className='card-body'>
                <h5 className='card-title'>{props.produce}</h5>
                <p className='card-text'>{props.description}</p>
            </div>
            <div className='card-footer text-muted'>Sold {props.sold}</div>
        </div>
    );
}

export default OurFoods;
