import merch from './Merchandise.json';

export interface MerchColor {
    color: string;
    images: string[];
}

export interface MerchandiseItem {
    pid: number;
    product: string;
    thumbnail: string;
    price: number;
    sizes: string[];
    colors: MerchColor[];
}

export function getAllMerchandise(): MerchandiseItem[] {
    return merch;
}

export function getMerchandise(productId: number): MerchandiseItem {
    const product = merch.find(({ pid }) => pid === productId);
    if (product) return product;
    throw new Error(`No merch with id: ${productId}`);
}
