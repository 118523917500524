import { XCircleIcon } from '@primer/octicons-react';
import { PropsWithChildren, ReactElement, ReactNode, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './Overlay.css';

const root = ReactDOM.createRoot(document.getElementById('overlay-root') as HTMLElement);

function Overlay(props: PropsWithChildren<Record<string, unknown>>) {
    return (
        <div className={`overlay`}>
            <div className='overlay-title'>
                <button className='close-button' onClick={() => setOverlayGlobal(null)}>
                    <XCircleIcon size={36} />
                    <span className='ps-2'>Close</span>
                </button>
            </div>
            <div>{props.children}</div>
        </div>
    );
}

let setOverlayGlobal: React.Dispatch<
    React.SetStateAction<ReactElement<
        Record<string, unknown>,
        string | React.JSXElementConstructor<Record<string, unknown>>
    > | null>
>;

function OverlayRoot() {
    const [overlay, setOverlay] = useState<ReactElement | null>(null);

    setOverlayGlobal = setOverlay;

    return <div>{overlay}</div>;
}

function addOverlay(overlay: ReactElement) {
    setOverlayGlobal(overlay);
}

root.render(<OverlayRoot />);

export default Overlay;
export { addOverlay };
