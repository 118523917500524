import fieldTripImage from '../assets/branding/FieldTrip.png';
import FieldTripCalculator from '../components/cornmaze/FieldTripCalculator';

function openFieldTripForm() {
    window.open(
        'https://res.cloudinary.com/dyk8jzztf/image/upload/v1662647068/Broyans%20Farm/PDF/Corn_Maze_Field_Trip.pdf',
        '_blank',
    );
}

function FieldTrips() {
    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 col-md-5 bg-light ps-0 pe-0 d-flex flex-column justify-content-between'>
                    <h1 className='m-4 p-4 display-1'>Field Trips</h1>
                    <img src={fieldTripImage} alt='Field Trip' className='w-100' />
                </div>

                <div className='col p-4 m-4'>
                    <p className='mb-4 display-4'>
                        We encourage schools to bring their students to our farm for a field trip.
                        We offer a fun and educational experience.
                    </p>
                    <button className='btn btn-primary' onClick={openFieldTripForm}>
                        Download Payment Form
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FieldTrips;
