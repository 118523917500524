import { PropsWithChildren } from 'react';
import './RibbonH3.css';

function RibbonH3(props: PropsWithChildren<Record<string, unknown>>) {
    return (
        <div className='ribbon-protector'>
            <h3 className='ribbon'>
                <span className='ribbon-content'>{props.children}</span>
            </h3>
        </div>
    );
}

export default RibbonH3;
