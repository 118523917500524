export default function GoogleMapsEmbed() {
    return (
        <div className='mapouter w-100'>
            <div className='gmap_canvas' style={{ background: '#009e15' }}>
                <iframe
                    style={{ minHeight: '20rem' }}
                    className='w-100'
                    title="Map of Broyan's Farm Market &amp; Country Store"
                    key='broyans-map'
                    id='gmap_canvas'
                    src='https://maps.google.com/maps?q=493%20Berwick%20Hazleton%20Highway,%20Nescopeck%20PA&t=&z=11&ie=UTF8&iwloc=&output=embed'
                    frameBorder='0'
                    scrolling='no'
                    marginHeight={0}
                    marginWidth={0}></iframe>
            </div>
        </div>
    );
}
