import R from '../../resources';
import RibbonH3 from '../ui/RibbonH3';

function Spices() {
    return (
        <div className='container p-4'>
            <div className='row'>
                <div className='col-12 col-md-5'>
                    <RibbonH3>{R.strings.produce.spices.title}</RibbonH3>
                    <p>{R.strings.produce.spices.description}</p>
                    <div className='row'>
                        <img
                            src={R.strings.produce.spices.small_image.source}
                            alt={R.strings.produce.spices.small_image.alt}
                        />
                    </div>
                </div>
                <div className='col'>
                    <img
                        src={R.strings.produce.spices.large_image.source}
                        alt={R.strings.produce.spices.large_image.alt}
                        className='w-100'
                    />
                </div>
            </div>
        </div>
    );
}

export default Spices;
