import './HomepageHero.css';
import Overlay, { addOverlay } from '../ui/Overlay';
import RibbonH3 from '../ui/RibbonH3';
import background from '../../assets/branding/broyans-hero.png';
import R from '../../resources';

function HomepageHero() {
    return (
        <div id='homepage-hero' className='hero container-fluid'>
            <div className='row p-md-4'>
                <div id='hero-right' className='col'>
                    <img src={background} alt={R.strings.short_name} className='w-100' />
                </div>
                <div
                    id='hero-left'
                    className='col-12 col-md-8 glass d-flex flex-column justify-content-around'>
                    <h1>
                        {R.strings.short_name}: <small>{R.strings.slogan}</small>
                    </h1>
                    <p>
                        {R.strings.homepage.greeting}
                        <br />
                        {R.strings.homepage.hero_message}
                    </p>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <button
                                className='btn btn-light w-100'
                                onClick={() => {
                                    addOverlay(
                                        <Overlay>
                                            <RibbonH3>{R.strings.homepage.farm_tour}</RibbonH3>
                                            <div
                                                className='d-flex justify-content-around'
                                                style={{ height: '50vh' }}>
                                                <iframe
                                                    width='50%'
                                                    height='100%'
                                                    src={R.strings.farm_tour_embed_url}
                                                    title={
                                                        R.strings.homepage
                                                            .youtube_video_player_title
                                                    }
                                                    frameBorder='0'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    allowFullScreen={true}></iframe>
                                            </div>
                                        </Overlay>,
                                    );
                                }}>
                                {R.strings.homepage.farm_tour}
                            </button>
                        </div>
                        <div className='col-12 col-md-6'>
                            <a
                                className='btn btn-light w-100'
                                href={R.strings.youtube_channel_url}
                                target='_blank'
                                rel='noreferrer'>
                                {R.strings.homepage.channel_button_text}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomepageHero;
