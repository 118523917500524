import background from '../assets/heros/merch-hero-bg.png';
import { useMetaDescription } from '../hooks/meta';
import HeroTitleLayout from '../layout/HeroTitleLayout';
import { StoreItemProps } from '../models/layout-props';
import { getAllMerchandise } from '../resources/Merchandise';

export default function Store() {
    useMetaDescription('store');

    return (
        <HeroTitleLayout title='Merch Store' background={background}>
            <div className='row'>
                <StoreList />
            </div>
        </HeroTitleLayout>
    );
}

function StoreList() {
    return (
        <>
            {getAllMerchandise().map(merch => (
                <StoreItem
                    key={merch.pid}
                    productId={merch.pid}
                    item={merch.product}
                    image={merch.thumbnail}
                    price={merch.price}
                />
            ))}
        </>
    );
}

export function StoreItem({ item, image, price, productId }: StoreItemProps) {
    const fmtPrice = (price: number) => {
        return `$${price.toFixed(2)}`;
    };

    return (
        <div className='col-6 col-lg-3'>
            <div className='card w-100'>
                <img className='card-img-top' src={image} alt={item} />
                <div className='card-body'>
                    <h5 className='card-title d-flex justify-content-between'>
                        {item}
                        <small className='text-success'>{fmtPrice(price)}</small>
                    </h5>
                    <button
                        className='btn btn-primary w-100'
                        onClick={() => {
                            window.location.hash = `#/store/product/${productId}`;
                        }}>
                        View
                    </button>
                </div>
            </div>
        </div>
    );
}
