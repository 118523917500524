function Footer() {
    return (
        <div className='footer container-fluid p-4 bg-dark text-light'>
            <div className='row p-4'>
                <div className='col'>Copyright &copy; 2022 Broyan's Farm Produce</div>
                <div className='col text-end'>
                    493 Berwick Hazleton Highway Nescopeck, PA 18635
                    <br />
                    (570) 379-3727
                </div>
            </div>
        </div>
    );
}

export default Footer;
