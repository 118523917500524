import background from '../assets/heros/fresh-produce.jpg';
import BakedGoods from '../components/produce/BakedGoods';
import DeliFood from '../components/produce/DeliFood';
import OurFoods from '../components/produce/OurFoods';
import Spices from '../components/produce/Spices';
import { useMetaDescription } from '../hooks/meta';
import HeroTitleLayout from '../layout/HeroTitleLayout';

export default function OurProducePage() {
    useMetaDescription('produce');

    return (
        <HeroTitleLayout title='Our Produce' background={background}>
            <OurFoods />
            <BakedGoods />
            <Spices />
            <DeliFood />
        </HeroTitleLayout>
    );
}
