import { useEffect, useState } from 'react';
import { getMerchandise } from '../resources/Merchandise';
import { getPath } from '../util/URLHash';
import MerchPage from './MerchPage';
import Store from './Store';

export default function StoreRouter() {
    const [productId, setProductId] = useState(
        getPath()[1] === 'product' ? parseInt(getPath()[2]) ?? null : null,
    );

    useEffect(() => {
        function hashChangeListener() {
            setProductId(getPath()[1] === 'product' ? parseInt(getPath()[2]) ?? null : null);
        }

        window.addEventListener('hashchange', hashChangeListener);

        return () => void window.removeEventListener('hashchange', hashChangeListener);
    });

    if (productId) {
        const { product, thumbnail, price, sizes, colors } = getMerchandise(productId);

        return (
            <MerchPage
                productId={productId}
                thumbnail={thumbnail}
                product={product}
                price={price}
                sizes={sizes}
                colors={colors}
            />
        );
    }

    return <Store />;
}
