import strings from './values/strings.json';
import * as data from './data';
import * as assets from './assets';

const R = {
    strings,
    data,
    assets,
};

export default R;
