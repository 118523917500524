import descriptions from '../resources/MetaDescriptions.json';

export function useMetaDescription(description: keyof typeof descriptions) {
    const metaDescription = Array.from(document.querySelectorAll('meta')).find(
        meta => meta.getAttribute('name') === 'description',
    );

    if (metaDescription) {
        metaDescription.setAttribute('content', description);
    } else {
        const newMetaDescription = document.createElement('meta');
        newMetaDescription.setAttribute('name', 'description');
        newMetaDescription.setAttribute('content', description);
        document.head.appendChild(newMetaDescription);
    }
}
