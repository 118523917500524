import { HoursItemProps } from '../../models/component-props';

function Hours() {
    return (
        <>
            <h4>Hours</h4>
            <p>
                <table>
                    <tbody>
                        <HoursItem days='Monday' closed />
                        <HoursItem days='Tuesday – Friday' open='7 AM' close='6 PM' />
                        <HoursItem days='Saturday' open='8 AM' close='5 PM' />
                        <HoursItem days='Sunday' open='10 AM' close='5 PM' />
                    </tbody>
                </table>
            </p>
        </>
    );
}

function HoursItem({ days, open = '9 AM', close = '5 AM', closed = false }: HoursItemProps) {
    function Data() {
        return !closed ? (
            <>
                <td className='text-end'>{open}</td>
                <td className='ps-2 pe-2'>&ndash;</td>
                <td>{close}</td>
            </>
        ) : (
            <td colSpan={3} className='text-end'>
                Closed
            </td>
        );
    }

    return (
        <tr>
            <td className='pe-4'>
                <strong>{days}</strong>
            </td>
            <Data />
        </tr>
    );
}

export default Hours;
