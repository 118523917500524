export function getPath(): string[] {
    return window.location.hash
        .substring(2)
        .split('/')
        .filter(s => s.trim().length > 0);
}

export function navigateTo(path: string | string[]) {
    if (Array.isArray(path)) window.location.hash = `/${path.join('/')}`;
    else {
        if (path.charAt(0) !== '/') path = `/${path}`;
        window.location.hash = path;
    }
}
