import { LabeledDataProps } from '../../models/component-props';
import './LabeledData.css';

export default function LabeledData({ label, data }: LabeledDataProps) {
    return (
        <div className='labeled-data d-flex flex-column me-4'>
            <span className='label text-primary'>{label}</span>
            <span className='data'>{data}</span>
        </div>
    );
}
