import { StorageDataType } from '../models/storage';

export function getData(key: string): StorageDataType {
    const data = localStorage.getItem(`broyans-farm.${key}`);

    if (!data) return null;
    return JSON.parse(data);
}

export function setData(key: string, value: StorageDataType): void {
    if (!value) localStorage.removeItem(key);
    else localStorage.setItem(`broyans-farm.${key}`, JSON.stringify(value));
}
