import { IonIcon } from '@ionic/react';
import { cartOutline } from 'ionicons/icons';
import { useEffect } from 'react';
import { navigateTo } from '../../util/URLHash';
import './Navbar.css';
import brandLogo from '../../assets/branding/brand.png';
import navbarItems from './navbar-items.json';
import { NavbarLinkProps } from '../../models/component-props';

function NavbarLink(props: NavbarLinkProps) {
    return (
        <a
            className={`nav-link ${window.location.hash === `#/${props.hash}` ? 'active' : ''}`}
            aria-current={window.location.hash === `#/${props.hash}` ? 'page' : undefined}
            href={`#/${props.hash}`}>
            {props.label}
        </a>
    );
}

function Navbar() {
    useEffect(() => {
        const navbar = document.querySelector('nav.navbar');
        navbarHeight = navbar?.clientHeight || 76;
    }, []);

    return (
        <nav className='navbar navbar-expand-lg navbar-light bg-light'>
            <div className='container-fluid'>
                <a className='navbar-brand' href='/'>
                    <img src={brandLogo} alt='Broyans Farm Produce' height='50' className='me-1' />
                </a>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                    <ul className='navbar-nav mb-2 mb-lg-0 me-0 w-100'>
                        {navbarItems.map(({ label, hash }) => (
                            <li key={label} className='nav-item'>
                                <NavbarLink label={label} hash={hash} />
                            </li>
                        ))}
                    </ul>
                    <div className='d-flex justify-content-end w-100'>
                        <button
                            className='shopping-cart-button btn d-flex'
                            onClick={() => void navigateTo('cart')}>
                            <IonIcon icon={cartOutline} />
                        </button>
                    </div>
                </div>
            </div>
        </nav>
    );
}

let navbarHeight = 76;

export default Navbar;
export { navbarHeight };
