export default function Address() {
    return (
        <div itemScope itemType='https://schema.org/PostalAddress'>
            <h4>Address</h4>
            <p itemProp='streetAddress' className='mb-0'>
                493 Berwick-Hazleton Highway
            </p>
            <p>
                <span itemProp='addressLocality'>Nescopeck</span>,{' '}
                <span itemProp='addressRegion'>PA</span> <span itemProp='postalCode'>18635</span>
            </p>
        </div>
    );
}
