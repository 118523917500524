import { IncludedItemProps } from '../../models/component-props';
import R from '../../resources';
import styles from './Included.module.css';

const Included = {
    Title: ({ title = '', subtitle = '' }) => {
        function getSubtitle() {
            if (subtitle) {
                return <small>{subtitle}</small>;
            }
        }

        return (
            <h3>
                {title} {getSubtitle()}
            </h3>
        );
    },

    Item: ({ title, icon }: IncludedItemProps) => {
        return (
            <div className='col'>
                <div className='card border-0 bg-transparent display-flex align-items-center'>
                    <img
                        src={R.assets[icon as keyof typeof R.assets]}
                        alt={title}
                        className={`${styles.Icon} card-img-top rounded-circle bg-light${styles.Icon} `}
                    />
                    <div className='card-body'>
                        <h5 className='card-title text-center'>{title}</h5>
                    </div>
                </div>
            </div>
        );
    },
};

const WhatsIncluded = () => {
    return (
        <div className={`container-fluid ${styles.WhatsIncluded}`}>
            <div className={`${styles.Green1} row pt-4 pb-4`}>
                <div className='col'></div>
                <p className={`${styles.Greeting} col-10 col-md-8 col-lg-6`}>
                    {R.strings.cornmaze.greeting}
                </p>
                <div className='col'></div>
            </div>
            <div className={`${styles.Green2} row p-4`}>
                <Included.Title title='$10 admission includes...' />
            </div>
            <div className={`${styles.Green2} row p-4`}>
                {R.data.cornmaze.whats_included_10.map((item, index) => (
                    <Included.Item key={index} {...item} />
                ))}
            </div>
            <div className={`${styles.Green3} row p-4`}>
                <Included.Title
                    title='$20 admission includes'
                    subtitle='everything above plus...'
                />
            </div>
            <div className={`${styles.Green3} row p-4`}>
                {R.data.cornmaze.whats_included_20.map((item, index) => (
                    <Included.Item key={index} {...item} />
                ))}
            </div>
            <div className={`${styles.Green4} row p-4`}>
                <div className='alert alert-success-outline alert-outline mb-0'>
                    <h5 className='alert-heading'>$50 Season Pass</h5>
                    <p className='m-0'>
                        The season pass includes unlimited admission with everything above included
                        for free with each visit. The season pass also includes 1 free wagon ride
                        and smores package each weekend.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Included;
export { WhatsIncluded };
