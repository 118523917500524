import { PropsWithChildren } from 'react';
import Hero, { HeroTitle } from '../components/heros/Hero';

interface HeroTitleLayoutProps {
    title: string;
    background: string;
}

const HeroTitleLayout = ({
    title,
    background,
    children,
}: PropsWithChildren<HeroTitleLayoutProps>) => {
    return (
        <div>
            <Hero background={background}>
                <HeroTitle title={title} />
            </Hero>

            <div className='container mt-4 mb-4'>{children}</div>
        </div>
    );
};

export default HeroTitleLayout;
export type { HeroTitleLayoutProps };
