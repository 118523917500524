import R from '../../resources';
import RibbonH3 from '../ui/RibbonH3';

function DeliFood() {
    return (
        <div className='container p-4'>
            <div className='row'>
                <div className='col'>
                    <RibbonH3>{R.strings.produce.deli.title}</RibbonH3>
                </div>
            </div>

            <div className='row'>
                <div className='col-12 col-lg-4'>
                    <img
                        src={R.strings.produce.deli.image.source}
                        alt={R.strings.produce.deli.image.alt}
                        className='w-100 mb-2'
                    />
                    <p>{R.strings.produce.deli.description}</p>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <h4>{R.strings.produce.deli.meats_title}</h4>
                    <ul className='list-group'>
                        {R.strings.produce.deli.meats.map((meat, index) => (
                            <li key={index} className='list-group-item'>
                                {meat}
                            </li>
                        ))}
                    </ul>
                </div>

                <div className='col-12 col-md-6 col-lg-4'>
                    <h4>{R.strings.produce.deli.cheeses_title}</h4>
                    <ul className='list-group'>
                        {R.strings.produce.deli.cheeses.map((cheese, index) => (
                            <li key={index} className='list-group-item'>
                                {cheese}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DeliFood;
