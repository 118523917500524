import { useState } from 'react';
import { MerchColor } from '../resources/Merchandise';
import { addToCart } from '../util/CartManager';
import './MerchPage.css';

export interface MerchPageProps {
    productId: number;
    product: string;
    thumbnail: string;
    price: number;
    sizes: string[];
    colors: MerchColor[];
}

export interface SelectionButtonProps {
    label: string;
    selected: boolean;
    onClick: () => void;
}

export default function MerchPage({
    productId,
    product,
    thumbnail,
    price,
    sizes,
    colors,
}: MerchPageProps) {
    const [selectedSize, setSelectedSize] = useState(sizes[0]);
    const [selectedColor, setSelectedColor] = useState(colors.length > 0 ? colors[0].color : null);
    const [selectedImage, setSelectedImage] = useState(
        colors.length > 0 ? colors[0].images[0] : null,
    );

    function makeImage() {
        if (selectedImage) {
            return <img src={selectedImage} alt={product} className='w-100' />;
        }
        return null;
    }

    return (
        <div className='merch-page container'>
            <div className='row mt-4'>
                <div className='col'>
                    <button
                        className='btn btn-light'
                        onClick={() => (window.location.hash = '#/store')}>
                        Back to Store
                    </button>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-3'>
                    <div className='row'>
                        <div className='col'>{makeImage()}</div>
                    </div>
                    <div className='row'>
                        <div className='image-selector col d-flex'>
                            {colors
                                .find(currColor => currColor.color === selectedColor)
                                ?.images.map((image, _, allImages) => (
                                    <div
                                        key={image}
                                        className='image-selector-item border p-2'
                                        style={{
                                            maxWidth: '50%',
                                            width: `${100 / allImages.length}%`,
                                        }}
                                        onClick={() => setSelectedImage(image)}>
                                        <img
                                            key={image}
                                            src={image}
                                            alt={`${selectedColor} ${product}`}
                                            className='w-100'
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className='col'>
                            <h1>
                                {product} <small className='text-success'>${price}</small>
                            </h1>

                            <h2 className='product-config-title'>Size</h2>
                            {sizes.map(size => (
                                <SelectionButton
                                    key={size}
                                    label={size}
                                    selected={size === selectedSize}
                                    onClick={() => setSelectedSize(size)}
                                />
                            ))}

                            <h2 className='product-config-title mt-4'>Color</h2>
                            {colors.map(({ color }) => (
                                <SelectionButton
                                    key={color}
                                    label={color}
                                    selected={color === selectedColor}
                                    onClick={() => setSelectedColor(color)}
                                />
                            ))}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <button
                                className='btn btn-primary mt-4 mb-4'
                                onClick={() =>
                                    void addToCart({
                                        id: productId,
                                        thumbnail:
                                            colors.find(
                                                currColor => currColor.color === selectedColor,
                                            )?.images[0] ?? thumbnail,
                                        size: selectedSize,
                                        color: selectedColor ?? undefined,
                                    })
                                }>
                                Add to Cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SelectionButton({ label, selected = false, onClick }: SelectionButtonProps) {
    return (
        <button
            key={label}
            className={`btn btn-sm ${selected ? 'btn-dark' : 'btn-light'} me-1 mb-1`}
            onClick={onClick}>
            {label}
        </button>
    );
}
