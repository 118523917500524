import { CartItem } from '../models/cart';
import { getData, setData } from './Storage';

export function addToCart(item: CartItem) {
    const cart = (getData('cart') as CartItem[]) ?? [];
    cart.push(item);
    setData('cart', cart);
}

export function deleteFromCart(index: number) {
    const cart = (getData('cart') as CartItem[]) ?? [];
    cart.splice(index, 1);
    setData('cart', cart);
}

export function getEntireCart() {
    return getData('cart') as CartItem[];
}
