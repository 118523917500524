import { useEffect, useState } from 'react';

type AcceptableClasses = string | undefined | null;
type ClassListChanger = (...classes: AcceptableClasses[]) => void;

export function useClasses(...classes: AcceptableClasses[]): [string, ClassListChanger] {
    const [userProvidedClasses, setUserProvidedClasses] = useState(classes);
    const [className, setClassName] = useState(getClassList());

    useEffect(() => {
        setUserProvidedClasses(classes);
    }, [classes]);

    function getClassList() {
        return (
            userProvidedClasses.filter(
                cn => cn !== undefined && cn !== null && cn !== '',
            ) as string[]
        )
            .map(cn => cn.trim())
            .join(' ');
    }

    function changeClassName(...classes: AcceptableClasses[]) {
        setUserProvidedClasses(classes);
        setClassName(getClassList());
    }

    return [className, changeClassName];
}
