import { BakedGoodProps } from '../../models/component-props';
import R from '../../resources';
import RibbonH3 from '../ui/RibbonH3';

function BakedGoods() {
    return (
        <div className='container p-4'>
            <div className='row'>
                <div className='col'>
                    <RibbonH3>Baked Goods</RibbonH3>
                </div>
            </div>

            <div className='row'>
                {R.data.produce.bakery.map((good, index) => (
                    <BakedGood key={index} {...good} />
                ))}
            </div>
        </div>
    );
}

function BakedGood({ name, image, variants }: BakedGoodProps) {
    return (
        <div className='col-12 col-md-4'>
            <div className='card w-100 h-100'>
                <img className='card-img-top' src={image} alt='Sprinkle Rice Krispie Treats' />
                <div className='card-body'>
                    <h5 className='card-title'>{name}</h5>
                    <ul className='list-group list-group-flush'>
                        {variants.map((variant, index) => (
                            <li key={index} className='list-group-item'>
                                {variant}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default BakedGoods;
