import { IonIcon } from '@ionic/react';
import {
    arrowBackCircleOutline,
    arrowForwardCircleOutline,
    cartOutline,
    trashOutline,
} from 'ionicons/icons';
import LabeledData from '../components/ui/LabeledData';
import { useMetaDescription } from '../hooks/meta';
import { CartListItemProps } from '../models/layout-props';
import { getAllMerchandise } from '../resources/Merchandise';
import { deleteFromCart, getEntireCart } from '../util/CartManager';
import { navigateTo } from '../util/URLHash';
import './Cart.css';

export default function Cart() {
    useMetaDescription('cart');

    return (
        <div className='body shopping-cart container mt-4'>
            <div className='row mb-4'>
                <div className='col' />
                <div className='col-12 col-md-8 col-lg-6'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='d-flex justify-content-center align-items-center mb-4'>
                                <IonIcon icon={cartOutline} className='me-4' /> Your Cart
                            </h1>
                            <CartList />
                        </div>
                    </div>

                    <CartNavigation />
                </div>
                <div className='col' />
            </div>
        </div>
    );
}

export function CartNavigation() {
    return (
        <div className='row mt-4'>
            <div className='col d-flex justify-content-between'>
                <button className='btn btn-light d-flex align-items-center'>
                    <IonIcon icon={arrowBackCircleOutline} size='large' className='me-2' />
                    Back to Store
                </button>
                <button
                    className='btn btn-primary d-flex align-items-center'
                    onClick={() => void navigateTo('checkout')}>
                    Checkout
                    <IonIcon icon={arrowForwardCircleOutline} size='large' className='ms-2' />
                </button>
            </div>
        </div>
    );
}

export function CartListItem({ product, thumbnail, size, color, price, index }: CartListItemProps) {
    function SizeAndColorDetails() {
        const data = [];

        if (size) {
            data.push(<LabeledData key='size' label='Size' data={size} />);
        }

        if (color) {
            data.push(<LabeledData key='color' label='Color' data={color} />);
        }

        return <>{data}</>;
    }

    function deleteItem() {
        deleteFromCart(index);
        window.location.reload();
    }

    return (
        <li className='cart-list-item list-group-item'>
            <div className='row'>
                <div className='col-3 col-lg-2'>
                    <img src={thumbnail} alt={`${size} ${color} ${product}`} className='w-100' />
                </div>
                <div className='col'>
                    <div className='row'>
                        <div className='col d-flex justify-content-between'>
                            <h2 className='product-name'>{product}</h2>
                            <button className='btn text-danger p-0' onClick={deleteItem}>
                                <IonIcon icon={trashOutline} />
                            </button>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col d-flex'>
                            <SizeAndColorDetails />

                            <div style={{ flexGrow: '1' }} />
                            <LabeledData label='Price' data={`$${price}`} />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export function CartList() {
    return (
        <ul className='cart-list list-group list-group-flush mt-4'>
            {getEntireCart().map((item, index) => {
                const product = getAllMerchandise().find(_product => _product.pid === item.id);

                if (product) {
                    return (
                        <CartListItem
                            key={`${item.id}-${item.size}-${item.color}`}
                            thumbnail={item.thumbnail}
                            product={product.product}
                            size={item.size}
                            color={item.color}
                            price={product.price}
                            index={index}
                        />
                    );
                } else {
                    return <li className='cart-list-item list-group-item'>Could not find item</li>;
                }
            })}
            <CartTotal />
        </ul>
    );
}

export function CartTotal() {
    return (
        <li className='list-group-item'>
            <div className='row'>
                <div className='col'>Total</div>
                <div className='col text-end me-4'>
                    $
                    {getEntireCart().reduce((prev, curr) => {
                        const product = getAllMerchandise().find(
                            _product => _product.pid === curr.id,
                        );
                        if (product) return prev + product.price;
                        return prev;
                    }, 0)}
                </div>
            </div>
        </li>
    );
}
